/* eslint-disable @nx/enforce-module-boundaries */
import {
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@mybridge/ui/menu';
import {
  AddCircleFIlled,
  AdddCircleIcon,
  CheckMark,
  CheckMark1,
  ChevronDown,
  Globe,
} from '@mybridge/icons';
import { Text } from '@mybridge/ui/text';
import { HStack } from '@mybridge/ui/layout';
import { useContext, useEffect, useMemo, useState } from 'react';
import { PostVisibilityOptions } from '../../../snippets/dashboard/constants';
import { PostFormContext } from '../context';
import { Portal, Button, Tooltip, IconButton } from '@mybridge/ui';
import { useDispatch, useSelector } from 'react-redux';
import { companyPageActivity } from 'v4/store/actions/companyPages.actions';

export const PostVisibilityMenu = ({ handleClose, addHeaderField,isFromProfile=false }) => {
  const {
    mode,
    companyPosting,
    isDisable,
    submitPost,
    multiPost,
    setMultiPost,
    isLoading,
    hasContent,
    resetForm,
    closeModal,
    setCloseModal,
    textMaxLength,
    text,
    visibility = PostVisibilityOptions?.[0]?.value,
    setVisibility,
    gifs,
    companyId,
    images,
    videos,
    tagged,
    feeling,
    id
  } = useContext(PostFormContext) ?? {};

  const { currentProfile } = useSelector((state) => state.userProfile);
 const dispatch=useDispatch()
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Choose audience');

  useEffect(() => {
    if (closeModal &&currentProfile?.id) {
      handleClose && handleClose();
      setCloseModal(false);
      dispatch(companyPageActivity(currentProfile?.id));
    }
  }, [closeModal,currentProfile?.id]);

 

  useEffect(() => {
    if (currentProfile) {
      companyPosting(currentProfile);
    }
  }, [currentProfile]);
  // console.log("(!text || (text?.length >= textMaxLength))&&!hasContent:",isDisable || (!text || (text?.length > textMaxLength)) || !hasContent);
  // console.log("text?.length >= textMaxLength:",text?.length >= textMaxLength);
  // console.log("hasContent:",hasContent);
  // console.log("isDisable:",isDisable);
  // console.log("text:",text);
  // console.log("textMaxLength:",textMaxLength)

  const handleMenuClose = () => {
    setMenuOpen(false);
    handleClose && handleClose();
  };
console.log(companyId,"companyIdcompanyIdcompanyId")
  const submitAllPost = () => {
    const lastPost = {
      text,
      gifs,
      images,
      videos,
      tagged,
      feeling,
      mode,
      companyId,
      visibility,
     
    };


    submitPost(lastPost, isFromProfile);
    // if(mode === 'create' || mode === 'multi'){
      mode ==="edit"&&handleClose()
    // }
  };
console.log(mode,"companyIdcompanyId")
  return (
    <>
      <Menu onClose={() => setMenuOpen(false)} isOpen={menuOpen} closeOnSelect={false}>
        <MenuButton
          variant="transparent"
          // border="1px solid"
          borderColor="#E4E6EB"
          // padding="4px 12px"
          // height="auto"
          // borderRadius="8"
          fontSize="sm"
          width="202px"
          height="40px"
          padding="10px 14px 10px 14px"
          gap="10px"
          borderRadius="20px"
          border="1px 1px 0px 1px"
          opacity="0px"
          boxShadow="0px 2px 6px 0px #00000033"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <HStack>
            {
              PostVisibilityOptions?.find((pvo) => pvo.value === visibility)
                ?.icon
            }
            <Text color="gray.600">
              {/* {
                PostVisibilityOptions?.find((pvo) => pvo.value === visibility)
                  ?.title
              } */}
              {/* Choose audience */}
              <Text color="gray.600">{selectedOption}</Text>
            </Text>
            {/* <ChevronDown /> */}
          </HStack>
        </MenuButton>

        <Portal>
          <MenuList
            zIndex={10000}
            shadow="base"
            position="absolute"
            top="-50px"
            minWidth="auto"
            width="202px"
            borderRadius="20px 20px 10px 10px"
          >
            <MenuGroup>
              <HStack
                padding="6px 12px"
                borderBottom="1px solid #E4E6EB"
                onClick={() => {
                  setSelectedOption('Choose audience'); // Update the selected option text
                  // handleMenuClose(); // Close the menu
                }}
              >
                {
                  PostVisibilityOptions?.find((pvo) => pvo.value === visibility)
                    ?.icon
                }
                <Text color="gray.600">Choose audience</Text>
              </HStack>
              {PostVisibilityOptions?.map((vo, voind) => (
                <MenuItem
                  key={voind}
                  onClick={(e) => {
                    e.stopPropagation()
                    setMenuOpen(true)
                    setVisibility(vo?.value);
                    setSelectedOption(vo?.title);
                  }}
                  icon={vo.icon}
                >
                  <HStack>
                    <Text flex={1}>{vo.title}</Text>
                    {vo?.value === visibility ? <CheckMark1 /> : ''}
                  </HStack>
                </MenuItem>
              ))}
            </MenuGroup>
          </MenuList>
        </Portal>
      </Menu>
      <HStack
        alignItems="center"
        flex={1}
        justifyContent="flex-end"
        gap="15px"
        position="relative"
      >
        <Tooltip
          hasArrow
          placement="top"
          variant="round"
          label="Add additional post"
        >
          <IconButton
            // isDisabled={
            //   (isDisable || !text || (text?.length > textMaxLength)) || !hasContent || isLoading
            // }
            isDisabled={!hasContent || isLoading}
            style={{ backgroundColor: 'transparent' }}
            onClick={addHeaderField}
          >
            <AddCircleFIlled width={22} height={22} color="#2A6797" />
          </IconButton>
        </Tooltip>
        <Button
          // isDisabled={ isDisable || (!text || (text?.length > textMaxLength)) || !hasContent}
          isDisabled={!hasContent}
          onClick={submitAllPost}
          variant="slimPrimary"
          isLoading={isLoading}
        >
          {mode === 'create' ? 'Post' : mode === 'multi' ? 'Post' : 'Update'}
        </Button>
      </HStack>
    </>
  );
};
