/* eslint-disable @nx/enforce-module-boundaries */
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { GifIconV2, ImageIconV2, SmileyIconV2, TagIconV2 } from '@mybridge/icons';
import {
  Box,
  CloseButton,
  HStack,
  IconButton,
  Input,
  Stack,
  Text,
} from '@mybridge/ui';
import { forwardRef, useContext, useMemo } from 'react';
import { PostFormContext } from '../../context';
import { MyEventsIconColored } from '@mybridge/icons/MyEvents';
import { useRouter } from 'next/router';
import { MyArticlesIconColored } from '@mybridge/icons/MyArticles';


export const PostFormMoreOptionsMenu = forwardRef(({ ...props }, ref) => {
  const {
    setMoreOptionsActiveTab,
    handleMediaFilesChange,
    fileInputRef,
    moreOptionsDisc,
    publishArticleDisc
  } = useContext(PostFormContext);
  const router = useRouter();

  const {setOpenMoreOptions} = props;

  const menuItems = useMemo(
    () => [
      {
        icon: <ImageIconV2 width={2} height={20} />,
        title: 'Photos/Videos',
        onClick: () => {
          // setMoreOptionsActiveTab('media')
          // setOpenMoreOptions(false);
          fileInputRef?.current?.click?.();
        },
      },
      {
        icon: <TagIconV2 width={20} height={20} />,
        title: 'Tag People',
        onClick: () => setMoreOptionsActiveTab('tag'),
      },
      {
        icon: <SmileyIconV2 width={20} height={20} />,
        title: 'Feeling/Activity',
        onClick: () => setMoreOptionsActiveTab('feeling'),
      },
      {
        icon: <GifIconV2 width={20} height={20} />,
        title: 'GIF',
        onClick: () => setMoreOptionsActiveTab('gif'),
      },
      {
        icon: <MyEventsIconColored width={20} height={20} />,
        title: 'Event',
        onClick: () => router.push('/myevents/create'),
      },
      {
        icon: <MyArticlesIconColored width={20} height={20} />,
        title: 'Article',
        onClick: () => publishArticleDisc.onOpen(),
      },
    ],
    []
  );
  return (
    <>
      <Box w="0" h="0" overflow="hidden">
        <Input
          multiple
          onChange={(e) => {
            handleMediaFilesChange?.(e);
            moreOptionsDisc?.onClose?.();
          }}
          ref={fileInputRef}
          w={0}
          h={0}
          visibility="hidden"
          type="file"
          accept=".jpg,.jpeg,.png,.mp4,.avi,.mov,.wmv"
        />
      </Box>
      <HStack mb={4}>
        
        <HStack justifyContent="center" flex={1}>
          <Text fontWeight="bold" color="brandPrimary.500">
            Add to your post
          </Text>
        </HStack>
        <CloseButton onClick={()=>setOpenMoreOptions(false)}/>
      </HStack>
      <HStack
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        {menuItems?.map?.((mi, miIndex) => (
          <HStack
            onClick={mi?.onClick}
            _hover={{ bg: '#EDEDED' }}
            cursor="pointer"
            p={2}
            borderRadius={5}
            key={miIndex}
            w={['full', 'full', '48.5%']}
          >
            <Stack
              p={2}
              borderRadius="5"
              bg="gray.100"
              alignItems="center"
              justifyContent="center"
            >
              {mi?.icon}
            </Stack>
            <Text>{mi.title}</Text>
          </HStack>
        ))}
      </HStack>


    </>
  );
});
