/* eslint-disable @nx/enforce-module-boundaries */
import { Avatar } from '@mybridge/ui/avatar';
import { Box } from '@mybridge/ui/layout';
import { useContext, useEffect, useState } from 'react';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import { ChevronDownIcon } from '@chakra-ui/icons';
import styles from 'v4/snippets/header/index.module.scss';
import { getAllCompanyPages } from 'v4/store/actions/companyPages.actions';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@mybridge/ui/modal';
import { Button } from '@mybridge/ui/button';
import { Heading } from '@mybridge/ui/heading';
import PostAsComponent from 'v4/components/post-form/postAsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { currentProfile } from 'v4/store/actions/user-profile.actions';
import Link from 'next/link';
import { PostFormContext } from '../context';

export const PostAvatar = ({ companyDetails, post, multiPost }) => {
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const userFullName = getUserFullName(userProfileInfo);
  const profilePic = getUserProfilePic(userProfileInfo);
  const [openPostModal, setOpenPostModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(userProfileInfo?.id);
  const { isCompany, setIsCompany,companyPosting } = useContext(PostFormContext) ?? {};
  const dispatch = useDispatch();

  const { companyPageList, companyPageCount } = useSelector(
    (state) => state.companyPage
  );

  useEffect(() => {
    if(companyPageList?.length === 0){
      dispatch(getAllCompanyPages());
    }
  }, []);

  return (
    <>
      {multiPost || isCompany ? (
        <Box
        // className={styles.avatarBox}
        // height={ post ? "" : "50px"}
        // py={1}
        // px={2}
        >
          <Link
            href={companyDetails ? `/c/${companyDetails?.slug}` : '/profile/me'}
          >
            <Avatar
              w={post ? '25px' : '45px'}
              h={post ? '25px' : '45px'}
              fontSize={post ? '10px' : '16px'}
              border=".5px solid #ffffff"
              name={companyDetails ? companyDetails?.name : userFullName}
              src={companyDetails ? companyDetails?.image : profilePic}
            />
          </Link>
        </Box>
      ) : (
        <Box
          className={styles.avatarBox}
          height={post ? '' : '50px'}
          py={1}
          px={2}
          bg="#E4E6EB"
        >
          <Link
            href={companyDetails ? `/c/${companyDetails?.slug}` : '/profile/me'}
          >
            <Avatar
              w={post ? '25px' : '35px'}
              h={post ? '25px' : '35px'}
              fontSize={post ? '10px' : '16px'}
              border=".5px solid #ffffff"
              name={companyDetails ? companyDetails?.name : userFullName}
              src={companyDetails ? companyDetails?.image : profilePic}
            />
          </Link>

          {companyPageCount > 0 && (
            <Box
              className={post ? styles.boxIconPost : styles.boxIcon}
              onClick={() => setOpenPostModal(true)}
            >
              <ChevronDownIcon width="18px" height="18px" />
            </Box>
          )}
        </Box>
      )}
      <Modal
        isOpen={openPostModal}
        onClose={() => setOpenPostModal(false)}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <form>
            <ModalHeader borderBottom={'1px solid #E1E1E1'}>
              <Heading
                className={styles?.alignCenter}
                size="md"
                color="brandGray.800"
                fontWeight="normal"
                fontSize="lg"
              >
                Posting As
              </Heading>
            </ModalHeader>
            <ModalCloseButton fontSize={12} />
            <ModalBody>
              <PostAsComponent
                setSelectedUser={setSelectedUser}
                selectedUser={selectedUser}
                onClose={() => setOpenPostModal(false)}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                variant="secondary"
                type="button"
                onClick={() => setOpenPostModal(false)}
                mr={3}
              >
                Cancel
              </Button>
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  const selectedProfile = companyPageList.filter(
                    (details) => details?.id == selectedUser
                  );
                  dispatch(currentProfile(selectedProfile?.[0]));
                  companyPosting(selectedProfile?.[0])
                  
                  setOpenPostModal(false);
                }}
                variant="primary"
                type="submit"
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};
