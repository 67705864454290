/* eslint-disable @nx/enforce-module-boundaries */
import { VerticalThreeDot } from '@mybridge/icons';
import {
  CloseButton,
  HStack,
  IconButton,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@mybridge/ui';
import Giphy from '@mybridge/ui/giphy';
import { forwardRef, useContext, useEffect, useState } from 'react';
import { PostFormContext } from '../context';
import { PostFormFeelingsPicker } from '../feelings-picker';
import { PostFormTagPeople } from '../tag-people';
import { PostFormMoreOptionsMenu } from './menu';
import { ChevronLeftIcon } from '@chakra-ui/icons';
export const PostFormMorePicker = forwardRef(({ children, ...props }, ref) => {
  const {
    setGifs,
    images,
    videos,
    moreOptionsActiveTab,
    setMoreOptionsActiveTab,
    moreOptionsDisc,
    handleMediaFilesChange,
    fileInputRef,
    openMoreOptions,
    setOpenMoreOptions
  } = useContext(PostFormContext);

  const onSelect = (gif) => {
    console.log(gif);
    setGifs?.([gif]);
    moreOptionsDisc?.onClose?.();
  };

  // const [openMoreOptions , setOpenMoreOptions] = useState(false);

  useEffect(()=>{
      if(!openMoreOptions){
        setMoreOptionsActiveTab(null);
        moreOptionsDisc?.onClose?.();
      }
  },[openMoreOptions])

  return (
    <>
      <Popover {...moreOptionsDisc}>
        <PopoverTrigger>
          <IconButton
            onClick={(e) =>{ setOpenMoreOptions(true); setMoreOptionsActiveTab(null) }}
            minW="4"
            p={1}
            borderRadius="8px"
            padding="6px"
          >
            <VerticalThreeDot width={20} height={20} />
          </IconButton>
        </PopoverTrigger>
        <PopoverContent w="md">
          <PopoverBody p={4} w="100%">
            {moreOptionsActiveTab?.length && (
              <>
                {moreOptionsActiveTab === 'feeling' ? (
                  <>
                    <PostFormFeelingsPicker />
                  </>
                ) : (
                  ''
                )}
                {moreOptionsActiveTab === 'tag' ? (
                  <>
                    <PostFormTagPeople />
                  </>
                ) : (
                  ''
                )}
                {moreOptionsActiveTab === 'gif' ? (
                  <>
                    <HStack>
                      <IconButton
                        onClick={(e) => setMoreOptionsActiveTab(null)}
                        variant="ghost"
                      >
                        <ChevronLeftIcon />
                      </IconButton>
                      <HStack flex={1}>
                        <Text fontWeight="bold" color="brandPrimary.500">
                          Choose Gif
                        </Text>
                      </HStack>
                      <CloseButton onClick={(e) => {
                        setOpenMoreOptions(false)
                        setMoreOptionsActiveTab(null);
                      }} />
                    </HStack>
                    <HStack justifyContent="center">
                      <Giphy columns={4} onSelect={onSelect} />
                    </HStack>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
            {openMoreOptions && !moreOptionsActiveTab?.length &&
               ( <PostFormMoreOptionsMenu setOpenMoreOptions={setOpenMoreOptions} />)
            }
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
});
